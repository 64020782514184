import {Toast, Overlay, Popup} from 'vant';
import {get} from '@/utils/request';
import {isWeiXin, isIos} from '@/utils/interaction';
import Clipboard from 'clipboard'
// import {useClipboard} from '@vueuse/core'
// const {copy} = useClipboard()

export default {
    name: 'Invite',

    components: {
        [Overlay.name]: Overlay,
        [Popup.name]: Popup,
    },
    data() {
        return {
            versions: process.env.VUE_APP_BASE_VERSIONS,
            ossUrl: process.env.VUE_APP_BASE_IMG_URL,
            params: {},
            origin: '',
            data: {},
            system: 0,
            load_over: false,
            invite_code: 0,
            url2browser: '',
            zhishi: false,
            showEnterprise: false,
            showBody: false,
        };
    },
    beforeCreate: function () {
        document.getElementsByTagName("body")[0].className = "body-invite";
    },
    beforeDestroy: function () {
        document.body.removeAttribute("class", "body-invite");
    },
    created() {
    },
    mounted() {
        this.onload();
    },
    methods: {
        // 初始化接口
        onload: function () {
            this.system = isIos() ? 2 : 1;
            this.params = this.$route.query;
            if (this.params?.code) {
                this.invite_code = this.params?.code.replace(/[^0-9]/g, "");
            } else {
                this.invite_code = '';
            }


            this.origin = window.document.location.origin;

            this.zhishi = isWeiXin();
            // if (!this.zhishi) {
            //
            // }
            this.onloadIndex(this.origin + '/api/inviter/btns.json');
            return true;
        },
        onloadIndex: function (url = '') {
            // console.log(url);
            Toast.loading({
                duration: 0,
                forbidClick: true,
                message: '',
            });
            const handleRequest = () => {
                let data = {
                    'invite_code': this.invite_code,
                    'time': new Date().getTime(),
                };
                get(url, data, (result) => {
                    if (result?.code === 0) {
                        // 请求成功
                        Toast.clear();
                        this.data = result?.data;

                        // const share_url = new URL(this.data.share_url);
                        // if (window.location.host !== share_url.host) {
                        this.showBody = true;
                        window.document.title = result?.data?.app_name + '下载';
                        this.url2browser = result?.data?.url2browser;
                        this.onloadBrowse();
                        this.toBrowser(); // 自动弹出浏览器打开
                        // }
                    } else if (result?.message) {
                        // console.log(result?.code, result?.message);
                        Toast(result?.message);
                    } else {
                        Toast('服务器请求失败！！');
                    }
                }, (e) => {
                    if (this.load_over) {
                        console.log('请检查网络！', e);
                        Toast('请检查网络！');
                    } else {
                        this.load_over = true;
                        this.onloadIndex('/h5/invite/browse'); // 记录ip并获取按钮数组
                    }
                });
            };
            handleRequest();
        },

        // 记录
        onloadBrowse: function () {
            if (this.load_over) return;
            if (!this.invite_code) return;
            const handleRequest = () => {
                try {
                    let data = {
                        'invite_code': this.invite_code,
                        'b': '1', // 仅记录
                        'time': new Date().getTime(),
                    };
                    get('/h5/invite/browse', data, () => {
                    }, () => {
                    }, false); // 仅记录ip
                } catch (e) {
                    console.log('')
                }
            };
            handleRequest();
        },

        down: function (item = '') {
            let url = item?.url;
            let type = item?.type;
            if (!url) return;

            // 是企业签名
            if (2 === type) {
                this.showEnterprise = true;
            }

            if (!this.toBrowser()) {
                window.location.href = url;
            }
        },

        toBrowser: function () {
            if (this.system === 1 && this.zhishi && this.url2browser) { // 是安卓并且是微信
                let url2browserUrl = this.url2browser + this.invite_code;
                window.open(url2browserUrl); // 弹出提示框
                return true;
            }
            return false;
        },

        handleCopy: function (item = '') {
            // console.log(this.invite_code);
            if (this.invite_code) {
                const text = '$' + this.data?.app_key + '$' + this.invite_code; // 复制文本
                let that = this;
                let clipboard = new Clipboard(".copy", {
                    text(trigger) {
                        console.log(trigger);
                        //返回字符串
                        return text;
                    },
                });
                // 复制成功
                clipboard.on("success", (e) => {
                    console.log(e, '复制成功');
                    clipboard.destroy();
                    that.down(item)
                });
                // //复制失败
                clipboard.on("error", (e) => {
                    console.log(e, '复制失败');
                    clipboard.destroy();
                    that.down(item)
                });
            } else {
                this.down(item)
            }
        },

    },

};
