import {Toast, Overlay} from 'vant';
import {post} from '@/utils/request';
import {openActivity, showShareDialog} from '@/utils/interaction';


export default {
    name: 'Share',

    components: {
        [Overlay.name]: Overlay,
    },
    data() {
        return {
            params: {},
            data: {},
            loading: true,
        };
    },
    beforeCreate: function () {
        document.getElementsByTagName("body")[0].className = "body-share";
    },
    beforeDestroy: function () {
        document.body.removeAttribute("class", "body-share");
    },
    created() {
    },
    mounted() {
        this.onload();
    },
    methods: {
        // 初始化接口
        onload: function () {
            this.params = this.$route.query;
            this.onloadIndex('h5/invite/index')
        },

        onloadIndex: function (url = '') {
            this.loading = true;
            Toast.loading({
                duration: 0,
                forbidClick: true,
                message: '',
            });
            const handleRequest = () => {
                let data = {};
                post(url, data, (result) => {
                    if (result?.code === 0) {
                        // 请求成功
                        Toast.clear();
                        this.data = result?.data;
                        this.loading = false;
                    } else if (result?.msg) {
                        Toast(result?.msg);
                    } else {
                        Toast('服务器请求失败！！');
                    }
                }, () => {
                    Toast('请检查网络！');
                }, {
                    token: this.params?.token
                });
            };
            handleRequest();
        },

        // 立即分享
        onShare: function () {
            showShareDialog(this.data.inviter_url, this.data.avatar);
        },

        // 奖励明细
        inviteList: function () {
            openActivity(2, 'live://user/inviteList');
        },

        // 邀请记录
        userList: function () {
            openActivity(2, 'live://user/userList');
        },


    },

};
