import Vue from 'vue'
import App from './App.vue'
import router from './router/index.js'
import 'normalize.css'
import './js/amfe-flexible.js';
import './style/index.scss';

Vue.config.productionTip = false


// 根据路由设置标题
router.beforeEach((to, from, next) => {
    if (to.meta.title) {
        document.title = to.meta.title;
    }
    next();
});

new Vue({
    render: h => h(App),
    router,
}).$mount('#app')
