import Vue from 'vue'
import VueRouter from 'vue-router'

import NotFound from '../components/NotFound.vue'
// import Home from '../views/Home.vue'
import Invite from '../views/invite/Index.vue'
import Share from '../views/share/Index.vue'
import Report from '../views/report/Index.vue'
import Config from '../views/config/Index.vue'


Vue.use(VueRouter)
const router = new VueRouter({
    routes: [
        {
            path: '/',
            name: 'Home',
            component: Invite,
            meta: {
                title: 'App下载'
            }
        },
        {
            path: '/invite',
            name: 'Invite',
            component: Invite,
            meta: {
                title: 'App下载'
            }
        },
        {
            path: '/share',
            name: 'Share',
            component: Share,
            meta: {
                title: '邀请好友'
            }
        },
        {
            path: '/report',
            name: 'Report',
            component: Report,
            meta: {
                title: '违规公布'
            }
        },
        {
            path: '/config/:password',
            name: 'Config',
            component: Config,
            meta: {
                title: '配置'
            }
        },
        {
            path: '/:path(.*)',
            component: NotFound,
            meta: {
                title: '404页面丢失'
            }
        },
    ]
})

export default router
