import CryptoJS from 'crypto-js'

const key = CryptoJS.enc.Utf8.parse(process.env.VUE_APP_BASE_AES_KEY) //秘钥

export const AES = {
  // 加密
  encrypt (str) {
    var encrypted = CryptoJS.AES.encrypt(CryptoJS.enc.Utf8.parse(str), key, {
      mode: CryptoJS.mode.ECB,
      padding: CryptoJS.pad.Pkcs7
    })
    return CryptoJS.enc.Base64.stringify(encrypted.ciphertext)
  },

  // 解密
  decrypt (base64) {
    return CryptoJS.AES.decrypt(base64, key, {
      mode: CryptoJS.mode.ECB,
      padding: CryptoJS.pad.Pkcs7
    }).toString(CryptoJS.enc.Utf8)
  },
}

export const Base64 = {
  // 编码
  encrypt (str) {
    return CryptoJS.enc.Base64.stringify(CryptoJS.enc.Utf8.parse(str))
  },

  // 解码
  decrypt (base64) {
    return CryptoJS.enc.Base64.parse(base64).toString(CryptoJS.enc.Utf8)
  },
}

