import $ from 'jquery';

/**
 * 获取html font-size
 * @returns {number}
 */
export const getFontSize = () => {
  return $('html').css('font-size').replace('px', '') * 1;
};

/**
 * 像素转rem
 * @param px
 * @param fontSize
 * @returns {number}
 */
export const px2rem = (px, fontSize = 37.5) => {
  if ('auto' === fontSize) {
    fontSize = getFontSize();
  }
  return (px / fontSize).toFixed(6) * 1;
};

/**
 * rem转像素
 * @param rem
 * @returns {number}
 */
export const rem2px = (rem) => {
  let fontSize = getFontSize();
  return (rem * fontSize).toFixed(2) * 1;
};

/**
 * 像素转当前页面像素
 * @param px
 * @returns {number}
 */
export const px2px = (px) => {
  return rem2px(px2rem(px));
};

/**
 * 字符串转json
 * @param str
 */
export function strToJson (str) {
  if (typeof str === 'string') {
    return JSON.parse(str);
  }
  return {};
}
