import {
    Toast,
    Overlay,
    Form,
    Field,
    CellGroup,
    Switch,
    Stepper,
    Button,
    RadioGroup,
    Radio,
    Grid,
    GridItem,
    NoticeBar,
    Icon
} from 'vant';

import {post} from '@/utils/request';

export default {
    name: 'Config',

    components: {
        [Overlay.name]: Overlay,
        [Form.name]: Form,
        [Field.name]: Field,
        [CellGroup.name]: CellGroup,
        [Switch.name]: Switch,
        [Stepper.name]: Stepper,
        [Button.name]: Button,
        [RadioGroup.name]: RadioGroup,
        [Radio.name]: Radio,
        [Grid.name]: Grid,
        [GridItem.name]: GridItem,
        [NoticeBar.name]: NoticeBar,
        [Icon.name]: Icon,
    },
    data() {
        return {
            password: '',
            data: {},
            loading: false,
        };
    },
    beforeCreate: function () {
        document.getElementsByTagName("body")[0].className = "body-config";
    },
    beforeDestroy: function () {
        document.body.removeAttribute("class", "body-config");
    },
    created() {
    },
    mounted() {
        this.onload();
    },
    methods: {
        // 初始化接口
        onload: function () {
            this.password = this.$route.params?.password;
            this.onloadIndex('h5/green/get_config/' + this.password)
        },
        onloadIndex: function (url = '') {
            this.loading = true;
            Toast.loading({
                duration: 0,
                forbidClick: true,
                message: '',
            });
            const handleRequest = () => {
                let data = {};
                post(url, data, (result) => {
                    if (result?.code === 0) {
                        // 请求成功
                        Toast.clear();
                        this.data = result?.data.config;
                        // console.log({...this.data})
                        this.loading = false;
                    } else if (result?.msg) {
                        Toast(result?.msg);
                    } else {
                        Toast('服务器请求失败！！');
                    }
                }, () => {
                    Toast('请检查网络！');
                });
            };
            handleRequest();
        },
        onSubmit: function (values) {
            delete values.undefined
            values['green_time'] = JSON.stringify(this.data.green_time)

            Toast.loading({
                duration: 0,
                forbidClick: true,
                message: '',
            });
            const handleRequest = () => {
                let url = 'h5/green/set_config/' + this.password;
                post(url, values, (result) => {
                    if (result?.code === 0) {
                        // 请求成功
                        Toast('保存成功');
                    } else if (result?.msg) {
                        Toast(result?.msg);
                    } else {
                        Toast('服务器请求失败！！');
                    }
                }, () => {
                    Toast('请检查网络！');
                });
            };
            handleRequest();
        },
        onDeleteGreenTime: function (index) {
            this.data.green_time.splice(index, 1)
        },
        onAddGreenTime: function () {
            this.data.green_time.push({"start": 0, "end": 1});
        },
    },

};
