import {Toast, Overlay, List, Col, Row} from 'vant';

import {post} from '@/utils/request';

export default {
    name: 'Report',

    components: {
        [Overlay.name]: Overlay,
        [List.name]: List,
        [Col.name]: Col,
        [Row.name]: Row,
    },
    data() {
        return {
            params: {},
            data: {},
            loading: false,
        };
    },
    beforeCreate: function () {
        document.getElementsByTagName("body")[0].className = "body-report";
    },
    beforeDestroy: function () {
        document.body.removeAttribute("class", "body-report");
    },
    created() {
    },
    mounted() {
        this.onload();
    },
    methods: {
        // 初始化接口
        onload: function () {
            this.params = this.$route.query;
            this.onloadIndex('h5/violate/index')
        },

        onloadIndex: function (url = '') {
            this.loading = true;
            Toast.loading({
                duration: 0,
                forbidClick: true,
                message: '',
            });
            const handleRequest = () => {
                let data = {};
                post(url, data, (result) => {
                    if (result?.code === 0) {
                        // 请求成功
                        Toast.clear();
                        this.data = result?.data;
                        this.loading = false;
                    } else if (result?.msg) {
                        Toast(result?.msg);
                    } else {
                        Toast('服务器请求失败！！');
                    }
                }, () => {
                    Toast('请检查网络！');
                }, {
                    token: this.params?.token,
                });
            };
            handleRequest();
        },
    },

};
