/**
 * 判断是否是微信浏览器的函数
 * @returns {boolean}
 */
export const isWeiXin = function () {
  //window.navigator.userAgent属性包含了浏览器类型、版本、操作系统类型、浏览器引擎类型等信息，这个属性可以用来判断浏览器类型
  var ua = window.navigator.userAgent.toLowerCase();
  //通过正则表达式匹配ua中是否含有MicroMessenger字符串
  if (ua.match(/MicroMessenger/i) == 'micromessenger') {
    return true;
  } else {
    return false;
  }
};

/**
 * 是否为ios系统
 * @returns {boolean}
 */
export const isIos = function () {
  var u = navigator.userAgent;
  return !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端
};

/**
 * 拼接url，get参数
 * @param url
 * @param params
 * @returns {string}
 */
export const addGetParams = (url, params) => {
  let gets = '';
  let i = 0;
  for (let key in params) {
    gets += (i !== 0 ? '&' : '') + key + '=' + params[key];
    i++;
  }
  return url + (gets !== '' ? (url.split('?').length >= 2 ? '&' : '?') : '') + gets;
};

/**
 * 显示分享弹窗
 * @param shareUrl [二维码内容，分享链接]
 * @param avatar [用户头像地址]
 */
export const showShareDialog = (shareUrl, avatar) => {
  try {
    window.openObject.showShareDialog(shareUrl, avatar);
  } catch (e) {
    try {
      window.webkit.messageHandlers.showShareDialog.postMessage({
        'shareUrl': shareUrl,
        'avatar': avatar,
      });
    } catch (e) {
      console.log('js调用分享弹窗', shareUrl, avatar);
    }
  }
};

/**
 * js调用可跳转app指定内页
 * @param linkType [类型:1=内置浏览器,2=指定内页]
 * @param linkUrl [地址]
 */
export const openActivity = (linkType, linkUrl) => {
  try {
    window.openObject.openActivity(linkType, linkUrl);
  } catch (e) {
    try {
      window.webkit.messageHandlers.openActivity.postMessage({
        'linkType': linkType,
        'linkUrl': linkUrl
      });
    } catch (e) {
      console.log('js调用可跳转app指定内页', linkUrl);
    }
  }
};
