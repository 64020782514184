import axios from 'axios';
import {AES} from '@/utils/aes';
import {strToJson} from '@/utils/common';

const api = axios.create({
    baseURL: process.env.VUE_APP_BASE_API,
    withCredentials: false, // send cookies when cross-domain requests
    timeout: 50000 // request timeout
});

const requestResolve = (data, aes) => {
    let errorData = {'code': 1, 'message': '请求出错，请联系管理员'}
    if (aes && typeof (data) != 'object') {
        try {
            data = strToJson(AES.decrypt(data));
        } catch (e) {
            data = errorData
        }
    }
    return data
}

export const get = (url, params = {}, successfn, errorfn, aes = true) => {
    api.get(url, {
        params
    }).then((res) => {
        successfn(requestResolve(res.data, aes))
    }, err => {
        errorfn(err);
    });
};

export const post = (url, data = {}, successfn, errorfn, headers = undefined, aes = true) => {
    if (headers === undefined) {
        headers = {
            'Content-Type': 'application/json',
        };
    }
    api.post(url, data, {
        headers: headers
    }).then((response) => {
        successfn(requestResolve(response.data, aes));
    }, err => {
        errorfn(err);
    });
};
